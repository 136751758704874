<template>
  <!-- begin::main -->
  <div class="layout-wrapper">
    <the-header></the-header>

    <div class="content-wrapper">
      <div class="content-body">
        <div class="content">
          <router-view />
        </div>

        <the-footer />
      </div>
    </div>
  </div>
  <!-- end::main -->
</template>

<script>
import TheHeader from "@/components/header/TheHeader.vue";
import TheFooter from "@/components/footer/TheFooter.vue";

export default {
  components: { TheHeader, TheFooter },
  mounted() {
    if (this.$route.path == '/') {
      this.$router.push("/files");
    }
  }
};
</script>
